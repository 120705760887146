<template>
  <div class="highlight-page">
    <template v-if="loadingInit">...</template>
    <template v-else>
      <v-tabs v-model="tab" centered @change="onTabChange">
        <v-tab href="#top-engage"> Top Engagement </v-tab>
        <v-tab href="#most-positive"> Most Positive </v-tab>
        <v-tab href="#most-negative"> Most Negative </v-tab>
        <v-tab href="#most-like"> Most Like </v-tab>
        <v-tab href="#most-retweet"> Most Retweet </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item id="top-engage">
          <SearchPageContent
            type="top-engage"
            :loading="loadingContent"
            :searchResult="topEngageResult"
            @changePage="onPageChange"
          />
        </v-tab-item>
        <v-tab-item id="most-positive">
          <SearchPageContent
            type="most-positive"
            :loading="loadingContent"
            :searchResult="mostPositiveResult"
            @changePage="onPageChange"
          />
        </v-tab-item>
        <v-tab-item id="most-negative">
          <SearchPageContent
            type="most-negative"
            :loading="loadingContent"
            :searchResult="mostNegativeResult"
            @changePage="onPageChange"
          />
        </v-tab-item>
        <v-tab-item id="most-like">
          <SearchPageContent
            type="most-like"
            :loading="loadingContent"
            :searchResult="mostLikeResult"
            @changePage="onPageChange"
          />
        </v-tab-item>
        <v-tab-item id="most-retweet">
          <SearchPageContent
            type="most-retweet"
            :loading="loadingContent"
            :searchResult="mostRetweetResult"
            @changePage="onPageChange"
          />
        </v-tab-item>
      </v-tabs-items>
    </template>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import api from '@/services/api';

import SearchPageContent from '@/components/Search/SearchPageContent.vue';

export default {
  name: 'Highlight',
  components: {
    SearchPageContent,
  },
  data() {
    return {
      loadingInit: true,
      loadingContent: true,
      tab: 'null',
      recordPerPage: 10,
      topEngageResult: {},
      mostPositiveResult: {},
      mostNegativeResult: {},
      mostLikeResult: {},
      mostRetweetResult: {},
    };
  },
  async created() {
    await this.$store.dispatch('filter/initFilterCriteria').catch(() => {});
    this.loadingInit = false;
  },
  methods: {
    ...mapActions({
      highlightKeywords: 'config/highlightKeywords',
    }),
    onTabChange(tab) {
      console.log('Tab', tab);
      this.getSearchData();
    },
    async setDataByType(result, type) {
      if (result && result.message) {
        // Do highlight
        await this.highlightKeywords({
          messageList: result.message.data,
        });
        if (type === 'top-engage') {
          this.topEngageResult = result.message;
        } else if (type === 'most-positive') {
          this.mostPositiveResult = result.message;
        } else if (type === 'most-negative') {
          this.mostNegativeResult = result.message;
        } else if (type === 'most-like') {
          this.mostLikeResult = result.message;
        } else if (type === 'most-retweet') {
          this.mostRetweetResult = result.message;
        }
      }
    },
    async getSearchData() {
      this.loadingContent = true;
      const data = JSON.parse(JSON.stringify(this.filterCriteria));
      data.paging = {
        recordPerPage: this.recordPerPage,
        page: 1,
      };
      let result;
      if (this.tab === 'top-engage') {
        data.sort = {
          field: 'engagement_score',
          direction: 'desc',
        };
        result = await api.getSearchData(data).catch(() => {});
      } else if (this.tab === 'most-positive') {
        data.sentiment = ['positive'];
        data.sort = {
          field: 'sentiment_score',
          direction: 'desc',
        };
        result = await api.getSearchData(data).catch(() => {});
      } else if (this.tab === 'most-negative') {
        data.sentiment = ['negative'];
        data.sort = {
          field: 'sentiment_score',
          direction: 'asc',
        };
        result = await api.getSearchData(data).catch(() => {});
      } else if (this.tab === 'most-like') {
        data.sort = {
          field: 'like_count',
          direction: 'desc',
        };
        result = await api.getSearchData(data).catch(() => {});
      } else if (this.tab === 'most-retweet') {
        data.sort = {
          field: 'retweet_count',
          direction: 'desc',
        };
        result = await api.getSearchData(data).catch(() => {});
      }
      if (result) {
        this.setDataByType(result, this.tab);
      }
      this.loadingContent = false;
    },
    async onPageChange(obj) {
      this.loadingContent = true;
      const { page, type } = obj;
      const paging = {
        recordPerPage: this.recordPerPage,
        page,
      };
      let criteria;
      if (type === 'top-engage') {
        criteria = { ...this.topEngageResult.criteria, paging };
      } else if (type === 'most-positive') {
        criteria = { ...this.mostPositiveResult.criteria, paging };
      } else if (type === 'most-negative') {
        criteria = { ...this.mostNegativeResult.criteria, paging };
      } else if (type === 'most-like') {
        criteria = { ...this.mostLikeResult.criteria, paging };
      } else if (type === 'most-retweet') {
        criteria = { ...this.mostRetweetResult.criteria, paging };
      }
      let result = await api.getSearchData(criteria).catch(() => {});
      if (result) {
        this.setDataByType(result, type);
      }
      this.loadingContent = false;
    },
  },
  watch: {
    filterCriteria: {
      handler: 'getSearchData',
    },
  },
  computed: {
    ...mapState({
      filterCriteria: (state) => state.filter.filterCriteria,
    }),
  },
};
</script>

<style lang="scss" scoped></style>
