<template>
  <v-container>
    <v-card v-if="!loading && (!messageList || messageList.length === 0)">
      <v-card-text> No data </v-card-text>
    </v-card>
    <v-layout justify-space-around v-if="loading">
      <v-progress-circular class="my-4" size="50" width="3" indeterminate />
    </v-layout>
    <MessageItem
      v-for="msg in messageList"
      :key="msg.info.id"
      :data="msg"
      :subCategoryName="subCategoryName"
      :truncate="true"
      @click="seeMore(msg)"
    />
    <v-container v-if="totalPage > 1">
      <v-pagination
        v-model="currentPage"
        :length="totalPage"
        :total-visible="7"
      />
    </v-container>
  </v-container>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import MessageItem from '@/components/Messages/MessageItem.vue';

export default {
  name: 'SearchPageContent',
  props: {
    type: String,
    searchResult: Object,
    loading: Boolean,
  },
  components: {
    MessageItem,
  },
  methods: {
    ...mapActions({
      seeMore: 'seemore/show',
    }),
  },
  computed: {
    ...mapState({
      filterCriteria: (state) => state.filter.filterCriteria,
    }),
    messageList() {
      if (this.searchResult && this.searchResult.data) {
        return this.searchResult.data;
      }
      return [];
    },
    subCategoryName() {
      if (this.searchResult && this.searchResult.subCategoryName) {
        return this.searchResult.subCategoryName;
      }
      return [];
    },
    totalPage() {
      if (this.searchResult && this.searchResult.totalPage) {
        return this.searchResult.totalPage;
      }
      return 1;
    },
    currentPage: {
      get: function () {
        if (this.searchResult && this.searchResult.currentPage) {
          return this.searchResult.currentPage;
        }
        return 1;
      },
      set: function (val) {
        this.$emit('changePage', {
          type: this.type,
          page: val,
        });
      },
    },
  },
};
</script>

<style lang="scss" scoped></style>
